import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "src/store/hooks";
import { storeAuthToken } from "src/store/tokenReducer";
import useRefetchSensitiveData from "./useRefetchSensitiveData";
import { useGetMemberQuery } from "src/api/main";

export const useSetToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const setToken = async () => {
    setIsFetching(true);
    const token = await getAccessTokenSilently();
    dispatch(storeAuthToken(token));
    setIsFetching(false);
  };

  return { setToken, isFetching };
};

export const useUpdateAppState = () => {
  const refetchSensitiveData = useRefetchSensitiveData();
  const { isAuthenticated } = useAuth0();

  const { setToken, isFetching } = useSetToken();

  useEffect(() => {
    async function updateToken() {
      if (document.visibilityState === "visible") {
        await setToken();
        refetchSensitiveData();
      }
    }

    if (isAuthenticated) {
      setToken();
      document.addEventListener("visibilitychange", updateToken);
    }

    return () => document.removeEventListener("visibilitychange", updateToken);
  }, [isAuthenticated]);

  return { isFetching };
};
