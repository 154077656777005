import React, { useEffect } from "react";
import {
  Blur,
  Container,
  Illustration,
  PageWrapper,
} from "src/pages/WelcomeOnboarding/styles";
import QRCodeWelcome from "src/pages/WelcomeOnboarding/components/QRCodeWelcome";
import illustration from "src/assets/images/app_preview.webp";
import SessionPageLayout from "src/components/SessionPageLayout";
import { useGetMemberQuery } from "src/api/main";
import Loading from "src/components/Loading";
import { isMobile } from "src/utils/isMobile";
import theme from "src/theme";
import RitualAnalytics from "client-analytics";

const WelcomeOnboarding = () => {
  const { data: member, isFetching } = useGetMemberQuery();
  const eventPayload = {
    name: member?.full_name,
    email: member?.email,
  };

  useEffect(() => {
    RitualAnalytics.track("transition_mobile_qr_open", eventPayload);
  }, []);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <SessionPageLayout
      memberInfo={member}
      style={{ padding: 0 }}
      headerSx={{
        padding: "40px 80px 0 80px",
        [theme.breakpoints.down("md")]: {
          padding: "8px 24px 0 24px",
        },
      }}
    >
      <Container>
        <PageWrapper>
          <QRCodeWelcome />
          {isMobile() && <Blur />}
          <Illustration src={illustration} alt="app_illustration" />
        </PageWrapper>
      </Container>
    </SessionPageLayout>
  );
};

export default WelcomeOnboarding;
