export const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

export const isPaymentMode = () => {
  const queryParams = getQueryParams();
  return queryParams.get("mode") === "payment";
};

export const shouldShowAddPartner = () => {
  const queryParams = getQueryParams();

  return queryParams.get("subscription") === "Couple" || isPaymentMode();
};
