import React, { useEffect } from "react";
import Loading from "src/components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

const LoginRedirect = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    loginWithRedirect({
      redirectUri: window.location.origin,
      test_login: urlParams.get("test_login"),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default LoginRedirect;
