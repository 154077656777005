import React from "react";
import {
  Avatar,
  Heading,
  Link,
  LoginWrapper,
  LogoWrapper,
  PageWrapper,
  Text,
} from "./styles";
import useSessionTitle from "src/hooks/useSessionTitle";
import { OurRitualLogo } from "src/assets/icons";
import { Button, Typography } from "ritual-ui";
import { Divider } from "@mui/material";
import image from "src/assets/images/login-profile.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { SUPPORT_EMAIL } from "src/constants/support";

const JoinSessionUnauthorized = () => {
  useSessionTitle("OurRitual Session");
  const { loginWithRedirect } = useAuth0();
  const { session_id } = useParams();

  const onLogin = () => {
    loginWithRedirect({
      redirectUri: window.location.origin,
      appState: { session_id },
    });
  };

  return (
    <PageWrapper className="page-wrapper">
      <LogoWrapper>
        <OurRitualLogo />
      </LogoWrapper>
      <LoginWrapper>
        <Avatar src={image} />

        <Heading variant="headline2">Let's Get You Logged In!</Heading>
        <Text>
          You'll be able to join your session as soon as we've verified that
          it's you.
        </Text>

        <Button
          id={""}
          style={{
            margin: "40px 0 24px",
            borderRadius: "40px",
            backgroundColor: "#FF5B4C",
          }}
          onClick={onLogin}
        >
          Log in
        </Button>
        <Typography style={{ fontSize: "14px" }}>
          Please note that you must be a registered and paying OurRitual Member
          to join this session.
        </Typography>
        <Divider style={{ margin: "50px 0 24px" }} />

        <Typography
          variant="subtitle"
          weight={500}
          style={{ fontSize: "16px" }}
        >
          Having trouble connecting to your session?
        </Typography>
        <Button
          variant="text"
          id={"support"}
          style={{ fontSize: "16px", marginTop: "12px" }}
          onClick={() => (window.location.href = SUPPORT_EMAIL)}
        >
          Contact support
        </Button>
      </LoginWrapper>
      <Typography
        variant="subtitle"
        style={{ textAlign: "center", fontSize: "16px" }}
      >
        If you've reached this page as an OurRitual relationship expert,{" "}
        <Link
          id="expert-join-link"
          variant="text"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_RITUALX_URL || "")
          }
        >
          use this link
        </Link>{" "}
        to join session
      </Typography>{" "}
    </PageWrapper>
  );
};

export default JoinSessionUnauthorized;
