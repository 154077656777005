import { styled } from "@mui/material";
import theme from "src/theme";
import Typography from "../Typography";
import { Button } from "ritual-ui";

export const Wrapper = styled("div")({
  background: theme.palette.background.paper,
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "40px 24px 24px 24px",
  textAlign: "center",
  gap: "15px",
  overflowY: "auto",
  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});

export const Header = styled("div")({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "40px",
  [theme.breakpoints.down("md")]: {
    marginBottom: 0,
  },
});

export const Logo = styled("div")({
  margin: "auto",
});

export const Info = styled("div")({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  fontFamily: "Noto Serif",
  right: "80px",
  [theme.breakpoints.down("md")]: {
    right: 0,
  },
});

export const MemberPicture = styled("img")({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  marginRight: "16px",
  [theme.breakpoints.down("md")]: {
    width: "32px",
    height: "32px",
    marginRight: 0,
  },
});

export const MemberText = styled(Typography)({
  fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "16px",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const LogoutButton = styled(Button)({
  color: theme.palette.primary.main,
  fontSize: "16px",
  width: "fit-content",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});
