import React, { Fragment } from "react";
import { Typography } from "src/components";
import { Divider, Step, StepText, Wrapper } from "./styles";
import theme from "src/theme";
import { NextStepsItem, PreviousStepsItem } from "../Stepper/StepItems";

interface VerticalStepperProps {
  steps: {
    name: string;
    completed?: boolean | null;
    hidden?: boolean | null;
    title: string;
    description: string;
  }[];
}

const VerticalStepper = ({ steps }: VerticalStepperProps) => {
  return (
    <Wrapper>
      {steps.map((item, i) => (
        <Fragment key={item.name}>
          <Step>
            {item.completed ? (
              <PreviousStepsItem />
            ) : (
              <NextStepsItem step={i + 1} />
            )}
            <StepText>
              <Typography variant="body-medium" color="#FF5B4C" weight={600}>
                {item.title}
              </Typography>
              <Typography variant="body-medium" color="#644F5D" weight={500}>
                {item.description}
              </Typography>
            </StepText>
          </Step>
          {i + 1 < steps.length && <Divider completed={!!item.completed} />}
        </Fragment>
      ))}
    </Wrapper>
  );
};

export default VerticalStepper;
