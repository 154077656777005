import React from "react";
import LayoutWithImage from "../Layout/LayoutWithImage";
import VerticalStepper from "../VerticalStepper";
import useOnboardingSteps, {
  useGetOnboardingNextStep,
} from "../../utils/useOnboardingSteps";
import { useGetMemberQuery } from "src/api/main";
import LoadingScreen from "../LoadingScreen";
import {
  useGetCompletedMatchingSessionQueryResolver,
  useGetOnboardingStatusQueryResolver,
} from "src/api/resolvers";
import { Button } from "src/components";
import { isMobile } from "src/utils/isMobile";

const InitialStep = () => {
  const steps = useOnboardingSteps();
  const { isLoading: onboardingLoading } =
    useGetOnboardingStatusQueryResolver();
  const { isLoading: matchingSessionLoading } =
    useGetCompletedMatchingSessionQueryResolver();
  const { data: member } = useGetMemberQuery();
  const queryParams = new URLSearchParams(window.location.search);

  const redirectToNextStep = useGetOnboardingNextStep();

  const firstName = member?.first_name || queryParams.get("first_name");

  if (onboardingLoading && matchingSessionLoading) {
    return <LoadingScreen />;
  }

  return (
    <LayoutWithImage
      image={require(`src/assets/images/onboarding_image${
        isMobile() ? "-mobile" : ""
      }.webp`)}
      title="Your Relationship Is Already Thanking You"
      subtitle={`${firstName}, you’ve taken a big step and chosen to experience the ${getPlan()}. Now we’ll just finalize your account and send you off to explore the app!`}
    >
      <VerticalStepper steps={steps} />
      <Button id={`onboarding_step-initial`} onClick={redirectToNextStep}>
        Continue
      </Button>
    </LayoutWithImage>
  );
};

const getPlan = () => {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.get("subscription") === "Couple") {
    return "Couple plan with your partner";
  }

  if (queryParams.get("mode") === "payment") {
    return "Matching plan";
  }

  return "Individual Plan";
};

export default InitialStep;
