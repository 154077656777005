import { Member } from "src/api/types";
import SHA256 from "crypto-js/sha256";
import { ChatMember, ChatType } from "src/types/chat";
import { PlanCategoryState } from "src/hooks/useGetPlanCategory";

abstract class Channel {
  member: Member;
  plan_category: PlanCategoryState;
  description = "";
  readOnly = false;
  membersCount = 0;
  clickId = "";
  error = {
    title: "",
    images: [] as string[],
  };
  type = ChatType.INDIVIDUAL;

  constructor(member: Member, plan_category: PlanCategoryState) {
    this.member = member;
    this.plan_category = plan_category;
  }

  public getExpectedChannel() {
    return {
      channelId: this.getChannelId(),
      members: [{ id: this.member.uuid }, ...this.getMembers()],
      description: this.description,
      readOnly: this.readOnly,
      show: this.shouldShow(),
      membersCount: this.membersCount,
      clickId: this.clickId,
      error: this.error,
      type: this.type,
    };
  }

  abstract shouldShow(): boolean;
  abstract getChannelId(): string;
  abstract getMembers(): ChatMember[];
}

export class IndividualChannel extends Channel {
  constructor(member: Member, plan_category: PlanCategoryState) {
    super(member, plan_category);
    this.description = "Talk with your Expert";
    this.membersCount = 2;
    this.clickId = "open_chat-individual";
    this.type = ChatType.INDIVIDUAL;
  }

  getChannelId(): string {
    const expertId = this.member.expert.replaceAll("-", "");
    const memberId = this.member.uuid.replaceAll("-", "");
    return `${memberId}${expertId}`;
  }

  getMembers(): ChatMember[] {
    return [{ id: this.member.expert }];
  }

  shouldShow(): boolean {
    return true;
  }
}

export class CoupleChannel extends Channel {
  constructor(member: Member, plan_category: PlanCategoryState) {
    super(member, plan_category);
    this.plan_category = plan_category;
    this.readOnly = !plan_category.isCouples;
    this.description = "Talk with your Expert and Partner";
    this.membersCount = 3;
    this.clickId = "open_chat-group";
    this.error = {
      title: "Group chat not yet enabled",
      images: [
        "https://useful-addition-be35771b29.media.strapiapp.com/alert_d3023774f6.png",
      ],
    };
    this.type = ChatType.GROUP;
  }

  getChannelId(): string {
    const sortedUuids = this.getMembers()
      .map(({ id }) => id)
      .sort();
    const concatenated = sortedUuids.join("");
    return SHA256(concatenated).toString();
  }

  getMembers(): ChatMember[] {
    const partner = this.member?.partner;

    return [
      { id: this.member.expert },
      {
        id: partner?.uuid || "",
        name: partner?.full_name,
        image: partner?.picture,
      },
    ].filter((value) => !!value.id);
  }

  shouldShow(): boolean {
    return this.plan_category.isCouples;
  }
}
