import {
  CMSResponseType,
  ModulePartGrowthworks,
  ModuleRelation,
} from "./types";

export const transformCmsResponse = (res: CMSResponseType<any, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

export const transformImage = (
  image?: CMSResponseType<{ url: string }, false>
) => {
  return image?.data?.attributes?.url;
};

interface Body {
  uuid: string;
  [key: string]: any;
}

export const updateQuery = (body: Body) => (draft: Body[]) => {
  const index = draft.findIndex(({ uuid }) => uuid === body.uuid);
  draft[index] = {
    ...draft[index],
    ...body,
  };
};

export const sortModuleRelation = (a: ModuleRelation, b: ModuleRelation) => {
  const partDifference = a.part_order - b.part_order;
  if (partDifference) return partDifference; // case when part_order not same

  return a.gw_order - b.gw_order; // part_order same then sort by gw_order
};

export const getModulePartGrowthworks = (response: ModuleRelation[]) => {
  const growthworks: ModulePartGrowthworks = {};
  response.forEach((item: ModuleRelation) => {
    const module = item.module.data.attributes.name;
    const part = item.part_order;
    const gwData = item.gw.data.attributes;
    const gw = {
      ...gwData,
      part_goal: item.part.data.attributes.part_goal,
      image: transformImage(gwData.image as any),
    };

    if (growthworks?.[module]?.[part])
      growthworks[module][part] = [...growthworks[module][part], gw];
    else {
      growthworks[module] = {
        ...growthworks[module],
        [part]: [gw],
      };
    }
  });
  return growthworks;
};
