import { Button } from "../../Button";
import InfoLayout from "../../InfoLayout";
import illustration from "src/assets/images/session_canceled-illustration.webp";
import LoginErrorFooter from "../components/SupportFooter/LoginErrorFooter";
import BackButton from "./components/BackButton";
import { isMobile } from "src/utils/isMobile";
import { useGetMemberQuery } from "src/api/main";
import React, { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Loading from "src/components/Loading";

const ErrorLoginPaymentLayout = () => {
  const { newRegistrationFlow } = useFlags();
  let params: string;
  const { data: member } = useGetMemberQuery();

  const memberQueryParams = new URLSearchParams({
    email: member?.email || "",
    phone: member?.phone_number || "",
    name: member?.full_name || "",
    uuid: member?.uuid || "",
  }).toString();

  useEffect(() => {
    if (newRegistrationFlow) {
      window.location.href =
        `${process.env.REACT_APP_NEW_REGISTRATION_URL}/no-active-plan` || "";
    }
  }, [newRegistrationFlow]);

  if (memberQueryParams) {
    let funnel_mode = "";
    if (window.location.href.includes("app-dev")) {
      funnel_mode = "test";
    }
    params = `?funnel_start=app&${memberQueryParams}&funnel_mode=${funnel_mode}&utm_medium=_____&utm_source=_____&utm_campaign=_____&utm_content=_____`;
  } else {
    params = "";
  }

  let pricingPageUrl = process.env.REACT_APP_PRICING_URL || "";

  if (newRegistrationFlow === undefined) {
    return <Loading />;
  }

  return (
    <InfoLayout
      sx={{
        backgroundColor: "white",
        margin: "auto",
        ".illustration": {
          maxWidth: !isMobile() ? "400px" : "224px",
        },
      }}
      large
      illustration={illustration}
      title={
        <>
          <span>You’re</span> almost there!
        </>
      }
      subtitle="You currently don’t have an active membership plan. To get started or to continue enjoying OurRitual, please choose the plan that best fits your needs."
      AdditionalContent={
        <Button
          sx={{ maxWidth: !isMobile() ? "300px" : "100%" }}
          className="action_button"
          aria-label="pricing-button"
          id="member_pricing_clicked"
          onClick={() => {
            window.location.href = pricingPageUrl + params;
          }}
        >
          See plans
        </Button>
      }
    >
      <BackButton />
      <LoginErrorFooter />
    </InfoLayout>
  );
};

export default ErrorLoginPaymentLayout;
