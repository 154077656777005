import React, { PropsWithChildren } from "react";
import { Button } from "../Button";
import { useAuth0 } from "@auth0/auth0-react";
import useLocationState from "src/hooks/useLocationState";
import RitualAnalytics from "client-analytics";
import { useGetMemberQuery } from "src/api/main";

interface LoginButtonProps extends PropsWithChildren {
  id: string;
  additionalParams?: any;
}

const LoginButton = ({ children, id, additionalParams }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();
  const href = useLocationState<string>("href", true);
  const { data: member } = useGetMemberQuery();

  const onClick = () => {
    const eventPayload = {
      name: member?.full_name,
      email: member?.email,
    };

    RitualAnalytics.track("account_verification_start", eventPayload);
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const phone_number = urlParams.get("phone");

    const props: { [key: string]: string } = {
      ...additionalParams,
    };

    if (urlParams.get("test_login")) {
      props.test_login = "true";
    }

    if (urlParams.get("test_signup")) {
      props.test_login = "true";
      props.screen_hint = "sign_up";
    }

    if (email) props.email = email;
    if (phone_number) props.phone_number = phone_number;

    loginWithRedirect({
      scope: "openid profile email",
      redirectUri: props.redirectTo || href || window.location.origin,
      ...props,
    });
  };

  return (
    <Button id={id} color="outlined" onClick={onClick}>
      {children}
    </Button>
  );
};

export default LoginButton;
