import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Member } from "src/api/types";
import { OurRitualLogo } from "src/assets/icons";
import MobileInfo from "./MobileInfo";
import {
  Header,
  Info,
  Logo,
  LogoutButton,
  MemberPicture,
  MemberText,
  Wrapper,
} from "./styles";
import { SxProps } from "@mui/material";

export interface SessionPageLayoutProps
  extends React.HTMLAttributes<HTMLDivElement> {
  memberInfo?: Member | null | undefined;
  headerSx?: SxProps<any>;
}

const SessionPageLayout = ({
  children,
  memberInfo,
  style,
  headerSx,
}: SessionPageLayoutProps) => {
  const { logout } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Wrapper style={style}>
      <Header sx={headerSx}>
        <Logo>
          <OurRitualLogo />
        </Logo>
        {memberInfo && (
          <Info>
            <MemberPicture
              src={memberInfo?.picture}
              alt={memberInfo?.full_name}
              onClick={() => setIsModalOpen(true)}
            />
            <MemberText>{memberInfo?.first_name}</MemberText>
            <LogoutButton
              id="logout"
              variant="text"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log out
            </LogoutButton>
          </Info>
        )}
      </Header>
      {isModalOpen && (
        <MobileInfo
          memberInfo={memberInfo}
          onClick={() => setIsModalOpen(false)}
        />
      )}
      {children}
    </Wrapper>
  );
};

export default SessionPageLayout;
