import React, { PropsWithChildren } from "react";
import Layout, { LayoutProps } from ".";
import {
  QuoteText,
  RightSideDesktopLogo,
  RightSideImagesContent,
  RightSideImagesWrapper,
  RightSideMobileLogo,
} from "./styles";
import { Typography } from "src/components";
import { useLocation } from "react-router-dom";
import { quotes } from "../../constants";

type LayoutWithImageProps = PropsWithChildren & RightSideProps & LayoutProps;

const LayoutWithImage = ({
  children,
  image,
  position,
  ...rest
}: LayoutWithImageProps) => {
  return (
    <Layout
      {...rest}
      LeftSide={children}
      RightSide={<RightSide image={image} position={position} />}
    />
  );
};

interface RightSideProps {
  image: string;
  position?: "top" | "bottom" | "center";
}

const RightSide = ({ image, position = "center" }: RightSideProps) => {
  const { pathname } = useLocation();
  const quote = quotes[pathname] || Object.values(quotes)[0];

  return (
    <RightSideImagesWrapper
      sx={{ backgroundImage: `url(${image})`, backgroundPosition: position }}
    >
      <RightSideMobileLogo />
      <RightSideImagesContent>
        <RightSideDesktopLogo />
        <div>
          <QuoteText variant="headline2">{quote.text}</QuoteText>
          <Typography variant="headline4">{quote.author}</Typography>
          <Typography variant="body-medium">{quote.description}</Typography>
        </div>
      </RightSideImagesContent>
    </RightSideImagesWrapper>
  );
};

export default LayoutWithImage;
