import { styled } from "@mui/material";
import { RitualSmallLogo, RitualWhiteLogo } from "src/assets/icons";
import { Typography } from "src/components";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  minHeight: "calc(var(--vh, 1vh) * 100)",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column-reverse",
    height: "fit-content",
  },

  "> div": {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
});

export const LeftSideWrapper = styled("div")({
  padding: `${getResponsiveValue(50, DESKTOP_MOCKUP_HEIGHT)} 50px`,
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  flex: 1,
  width: "45%",
  overflow: "auto",

  "#ritual_logo": {
    minHeight: "40px",
  },

  [theme.breakpoints.down("lg")]: {
    padding: "20px 24px",
    "#ritual_logo": {
      display: "none",
    },
  },
});

export const LeftSideLayout = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,

  paddingTop: getResponsiveValue(80, DESKTOP_MOCKUP_HEIGHT),

  [theme.breakpoints.down("sm")]: {
    paddingTop: "0px",
  },
});

export const LeftSideContent = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
  flex: 1,
});

export const Header = styled("div")({
  h1: {
    fontFamily: "Noto Serif",
    color: "#31232F",
    fontSize: "32px",
    fontWeight: 500,

    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  p: {
    marginTop: "16px",
    color: "#31232F",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
});

export const RightSideWrapper = styled("div")({
  backgroundColor: "#EEECE0",
  width: "55%",
});

export const RightSideImagesWrapper = styled("div")({
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  padding: 50,

  [theme.breakpoints.down("lg")]: {
    height: "35vh",
    padding: 0,
  },

  "h2,h4,p": {
    color: "white",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },

  h4: {
    marginTop: 40,
  },
  p: {
    marginTop: 6,
  },
});

export const RightSideMobileLogo = styled(RitualWhiteLogo)({
  display: "none",
  position: "absolute",
  left: "50%",
  top: "35px",
  transform: "translateX(-50%)",

  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
});
export const RightSideDesktopLogo = styled(RitualSmallLogo)({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
});

export const RightSideImagesContent = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",

  div: {
    marginTop: "auto",
  },

  h4: {
    marginTop: 40,
  },

  p: {
    marginTop: 6,
    fontWeight: 600,
  },
});

export const QuoteText = styled(Typography)({
  fontFamily: "Noto Serif",
  fontStyle: "italic",
  textTransform: "capitalize",
});
