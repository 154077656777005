import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetMemberQuery,
  useSendCompleteOnboardingEventMutation,
} from "src/api/main";
import { useGetOnboardingStatusQueryResolver } from "src/api/resolvers";
import { ROUTES } from "src/types/routes";
import { isMobile } from "src/utils/isMobile";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import { useFlags } from "launchdarkly-react-client-sdk";
import RitualAnalytics from "client-analytics";
import { isPaymentMode, shouldShowAddPartner } from "./helpers";

const useOnboardingSteps = () => {
  const { isAuthenticated } = useAuth0();
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();
  const queryParams = new URLSearchParams(window.location.search);
  const isMatchingCategory = useIsMatchingPlanCategory();

  const welcomeSessionCompleted =
    onboardingStatus?.welcome_session ||
    onboardingStatus?.welcome_session_skipped;
  const matchingSessionCompleted =
    onboardingStatus?.matching_session ||
    onboardingStatus?.matching_session_skipped;

  const isMatchingPlan =
    queryParams.get("mode") !== null ? isPaymentMode() : isMatchingCategory;

  const isCouplePlanHidden = onboardingStatus
    ? onboardingStatus?.partner_added === null
    : !shouldShowAddPartner();

  const steps = [
    {
      route: ROUTES.ONBOARDING_CREATE_ACCOUNT,
      completed: isAuthenticated,
      name: "Verify Account",
      loginKey: "verify",
      title: "Verify Your Account",
      description: "Confirm either your email or your phone number",
    },
    {
      route: isMatchingPlan
        ? ROUTES.ONBOARDING_BOOK_MATCHING_SESSION
        : ROUTES.ONBOARDING_BOOK_WELCOME_SESSION,
      completed: isMatchingPlan
        ? matchingSessionCompleted
        : welcomeSessionCompleted,
      name: isMatchingPlan ? "Book Matching Session" : "Book First Session",
      loginKey: isMatchingPlan ? "matching" : "welcome",
      title: "Book Your First Session",
      description: "Choose a time to meet your relationship Expert",
    },
    {
      route: ROUTES.ONBOARDING_ADD_PARTNER,
      completed:
        onboardingStatus?.partner_added ||
        onboardingStatus?.partner_added_skipped,
      name: "Invite Partner",
      loginKey: "partner",
      hidden: isCouplePlanHidden,
      title: "Invite Your Partner",
      description:
        "Fill in your partner’s contact details so they can complete their account",
    },
  ];

  return steps.filter(({ hidden }) => !hidden);
};

export const useGetOnboardingNextStep = () => {
  const steps = useOnboardingSteps();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sendCompleteEvent] = useSendCompleteOnboardingEventMutation();
  const { data: member } = useGetMemberQuery();
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();

  const { showNativeAppQr } = useFlags();

  const nextStep = steps.find(
    ({ completed, route }) => !completed && pathname !== route
  );

  const lastRoute =
    !isMobile() || showNativeAppQr
      ? ROUTES.ONBOARDING_WELCOME_LOGIN
      : ROUTES.CAROUSEL;

  const redirectToNextStep = () => {
    const pathname = nextStep?.route || lastRoute;
    const isOnboardingCompleted = pathname === lastRoute;

    if (isOnboardingCompleted) {
      window.analytics?.track("member_onboarding_redirect", {
        application: showNativeAppQr ? "native" : "webapp",
      });
      RitualAnalytics.track("member_onboarding_redirect", {
        application: showNativeAppQr ? "native" : "webapp",
      });
      sendCompleteEvent({
        memberId: member?.uuid || "",
        properties: { ...onboardingStatus },
      });
    }

    navigate({
      pathname,
      search: window.location.search,
    });
  };

  return redirectToNextStep;
};

export default useOnboardingSteps;
